<template>
  <div style='width:1220px;margin: 36px auto 50px auto;'>
    <div style="width:1220px;background:#f3f3f3;">
      <y-line :width="1220" :height="500" :data="userSum" :options="optionsUserSum"></y-line>
      <bar :width='1220' :height='500' :data='apiAccess' :options='optionsApiAccess'></bar>
      <bar :width='1220' :height='500' :data='userAccess' :options='optionsUserAccess'></bar>
      <!-- <bar-y :width='1020' :height='500' :data='dataY' :options='optionsY'></bar-y> -->
      <!-- <y-line :width="1020" :height="500" :data="dataLM" :options="optionsL"></y-line> -->
    </div>
  </div>
</template>
<script>
import Bar from './common/charts/bar-m'
import BarY from './common/charts/bar-y'
import YLine from './common/charts/line-m'
import mixin from './statistic'
export default {
  components: {Bar, BarY, YLine},
  mixins: [mixin],
  data () {
    return {
      yearCounts: {1994: 1, 1996: 1, 2000: 2, 2001: 6, 2002: 15, 2003: 8, 2004: 24, 2005: 11, 2006: 18, 2007: 37, 2008: 64, 2009: 112, 2010: 182, 2011: 216, 2012: 215, 2013: 277, 2014: 349, 2015: 339, 2016: 365, 2017: 360, 2018: 397, 2019: 198},
      data: [
        {'value': 3, 'name': '红细胞'},
        {'value': 49, 'name': '血清'},
        {'value': 4, 'name': '肿瘤组织'},
        {'value': 4, 'name': '肿瘤旁组织'},
        {'value': 4, 'name': '正常组织'},
        {'value': 16, 'name': '血浆'},
        {'value': 2, 'name': 'DNA'},
        {'value': 9, 'name': '粪便'},
        {'value': 15, 'name': 'RNA'},
        {'value': 12, 'name': '细胞系'},
        {'value': 15, 'name': '冻干血浆'}
      ],
      dataM: [
        {'value': [3, 11], 'name': '红细胞'},
        {'value': [49, 15], 'name': '血清'},
        {'value': [4, 7], 'name': '肿瘤组织'},
        {'value': [4, 6], 'name': '肿瘤旁组织'},
        {'value': [4, 3], 'name': '正常组织'}
        // {'value': 16, 'name': '血浆'},
        // {'value': 2, 'name': 'DNA'},
        // {'value': 9, 'name': '粪便'},
        // {'value': 15, 'name': 'RNA'},
        // {'value': 12, 'name': '细胞系'},
        // {'value': 15, 'name': '冻干血浆'}
      ],
      dataY: [
        {'value': 3, 'name': '红细胞'},
        {'value': 49, 'name': '血清'}
        // {'value': 4, 'name': '肿瘤组织'},
        // {'value': 5, 'name': '肿瘤旁组织'},
        // {'value': 4, 'name': '正常组织'},
        // {'value': 16, 'name': '血浆'}
        // {'value': 2, 'name': 'DNA'},
        // {'value': 9, 'name': '粪便'},
        // {'value': 15, 'name': 'RNA'},
        // {'value': 12, 'name': '细胞系'},
        // {'value': 15, 'name': '冻干血浆'}
      ],
      dataL: [
        {'value': 3, 'name': '2013'},
        {'value': 4, 'name': '2014'},
        {'value': 5, 'name': '2015'},
        {'value': 4, 'name': '2016'},
        {'value': 16, 'name': '2017'},
        {'value': 2, 'name': '2018'},
        {'value': 9, 'name': '2019'}
      ],
      dataLM: [
        {'value': [3, 11], 'name': '2013'},
        {'value': [4, 4], 'name': '2014'},
        {'value': [5, 7], 'name': '2015'},
        {'value': [4, 3], 'name': '2016'},
        {'value': [16, 9], 'name': '2017'},
        {'value': [2, 6], 'name': '2018'},
        {'value': [9, 8], 'name': '2019'}
      ],
      options: {
        title: {
          text: '影响因子变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        bar: {
          interval: 'auto',  // 'auto', 'none', 'half', 'one-third', 'one-five', 0, 10
          background: 'auto',  // 'auto', 'selected'
          colors: []
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'bottom'
        },
        x: {
          label: true,
          labelRotate: true,
          axis: true,
          tick: true
        },
        y: {
          label: true,
          // labelRotate: true,
          axis: true,
          tick: true
        },
        grid: {
          line: true
        },
        legend: ['第一组', '第二组']
      },
      optionsY: {
        title: {
          text: '影响因子变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        bar: {
          interval: 'auto',  // 'auto', 'none', 0, 10
          background: 'auto',  // 'auto', 'selected'
          overlap: true,
          colors: ['rgba(220,220,220,0.65)', '#228cd8']
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'bottom'
        },
        x: {
          label: true,
          labelRotate: false,
          axis: false,
          tick: false
        },
        y: {
          label: false,
          labelRotate: false,
          axis: true,
          tick: false
        },
        grid: {
          line: true
        }
      },
      optionsL: {
        title: {
          text: '影响因子变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        line: {
          boundaryGap: '5%',  // 20%, 0 10 两边轴内总空格
          colors: []
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'fixed'
        },
        x: {
          label: true,
          axis: true,
          tick: true
        },
        y: {
          label: true,
          axis: true,
          tick: true
        },
        grid: {
          line: true
        },
        legend: ['第一值', '第二值']  // ''/[], 数据value的label, array
      }
    }
  }
}
</script>
