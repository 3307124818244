export default {
  props: {
    width: {
      type: [Number, String],
      default: 200
    },
    height: {
      type: [Number, String],
      default: 100
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    },
    selectedData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      title: '',
      titleY: 0,
      titleStyle: {},

      marginTop: 0,
      marginRight: 0,
      marginBottom: 0,
      marginLeft: 0,
      barInterval: 0,  // 柱条间隔
      barBackground: 'auto',  // 柱条背景色
      barOverlap: false,
      barColors: ['#c23531', '#2f4554', '#61a0a8', '#d48265', '#91c7ae', '#749f83', '#ca8622', '#bda29a', '#6e7074', '#546570', '#c4ccd3'],

      legend: [],
      isGroupOne: true,

      hintPos: 'auto',
      currentName: '',
      currentValue: '',
      hintLeft: 0,
      hintTop: 0,
      hintLeftAuto: 0,
      hintTopAuto: 0,
      showHintTop: false,
      showHintBottom: false,
      showHintAuto: false,
      hintPosDis: 15,

      showXLabel: false,
      showXAxis: false,
      showXAxisTick: false,
      xlabelRotate: false,

      showYLabel: false,
      showYAxis: false,
      showYAxisTick: false,
      ylabelRotate: false,

      showGridLine: false
    }
  },
  computed: {
    viwBox () {
      return `0 0 ${this.width} ${this.height}`
    },
    grid () {
      let gd = {}
      gd.ltx = this.marginLeft
      gd.lty = this.marginTop
      gd.lbx = this.marginLeft
      gd.lby = this.height - this.marginBottom
      gd.rtx = this.width - this.marginRight
      gd.rty = this.marginTop
      gd.rbx = this.width - this.marginRight
      gd.rby = this.height - this.marginBottom
      gd.width = this.width - this.marginLeft - this.marginRight
      gd.height = this.height - this.marginTop - this.marginBottom
      if (gd.rbx <= 0 || gd.rby <= 0 || gd.width <= 0 || gd.height <= 0) {
        throw new Error('margin is bigger than gird\'s width or height')
      }
      return gd
    },
    barStatus () {
      let status = {}
      if (!this.selectedData || !this.selectedData.length) {
        this.data.forEach(item => {
          status[item.name] = true
        })
      } else {
        this.data.forEach(item => {
          status[item.name] = false
        })
        this.selectedData.forEach(item => {
          status[item.name] = true
        })
      }
      return status
    }
  },
  watch: {
    width (val) {
      if (val) {
        this.updateBarInterval()
        this.updateHintAutoPos()
      }
    },
    height (val) {
      if (val) { this.updateHintAutoPos() }
    },
    options (val) {
      if (val) { this.initOptions() }
    },
    'options.legend' (val) {
      if (val) { this.legend = val }
    },
    data (val) {
      if (val) { this.updateBarInterval() }
    }
  },
  created () {
    this.initOptions()
    this.updateBarInterval()
  },
  methods: {
    initOptions () {
      if (this.options.title) {
        this.title = this.options.title.text || ''
        this.titleY = this.options.title.top || 14
        this.titleStyle = this.options.title.style || {}
      }
      if (this.options.margin) {
        this.marginTop = this.options.margin.top || 0
        this.marginRight = this.options.margin.right || 0
        this.marginBottom = this.options.margin.bottom || 0
        this.marginLeft = this.options.margin.left || 0
      }
      if (this.options.x) {
        this.showXLabel = !!this.options.x.label
        this.showXAxis = !!this.options.x.axis
        this.showXAxisTick = !!this.options.x.tick
        this.xlabelRotate = !!this.options.x.labelRotate
      }
      if (this.options.y) {
        this.showYLabel = !!this.options.y.label
        this.showYAxis = !!this.options.y.axis
        this.showYAxisTick = !!this.options.y.tick
        this.ylabelRotate = !!this.options.y.labelRotate
      }
      if (this.options.grid) {
        this.showGridLine = !!this.options.grid.line
      }
      if (this.options.bar) {
        this.barBackground = this.options.bar.background || 'auto'
        if (this.options.bar.colors && this.options.bar.colors.length) {
          this.barColors = this.options.bar.colors
        }
        if (this.options.bar.overlap) this.barOverlap = !!this.options.bar.overlap
      }
      if (this.options.hint) {
        this.hintPos = this.options.hint.pos || 'auto'
        this.updateHintAutoPos()
      }
      if (this.options.legend && this.options.legend.length) {
        this.legend = this.options.legend
      }
    },
    handleMouseLeaveView () {
      this.hiddenHint()
    },
    handleMouseEnterHintTop () {
      this.showHint()
    },
    handleMouseLeaveHintTop () {
      this.hiddenHint()
    },
    handleMouseEnterBar (index, idx, e) {
      let color = this.barColors[idx % this.barColors.length]
      if (this.isGroupOne) {
        color = this.barColors[index % this.barColors.length]
      }
      let lightColor = this.lightenDarkenColor(color, 16)
      e.target.setAttribute('fill', lightColor)
    },
    handleMouseLeaveBar (index, idx, e) {
      let color = this.barColors[idx % this.barColors.length]
      if (this.isGroupOne) {
        color = this.barColors[index % this.barColors.length]
      }
      e.target.setAttribute('fill', color)
    },
    handleBarClick (item) {
      this.$emit('on-bar-click', item)
    },
    showHint () {
      if (this.hintPos === 'top') {
        this.showHintTop = true
      } else if (this.hintPos === 'bottom') {
        this.showHintBottom = true
      } else if (this.hintPos === 'auto') {
        this.showHintAuto = true
      }
    },
    hiddenHint () {
      if (this.hintPos === 'top') {
        this.showHintTop = false
      } else if (this.hintPos === 'bottom') {
        this.showHintBottom = false
      } else if (this.hintPos === 'auto') {
        this.showHintAuto = false
      }
    },
    updateHintAutoPos () {
      this.hintLeftAuto = this.width / 2
      this.hintTopAuto = this.height / 2
      this.updateHintPosDis()
    },
    updateHintPosDis () {
      let gridWidth = this.width - this.marginRight - this.marginLeft
      let gridHeight = this.height - this.marginBottom - this.marginTop
      if (gridWidth < 50 || gridHeight < 50) {
        this.hintPosDis = 10
      } else if (gridWidth < 100 || gridHeight < 100) {
        this.hintPosDis = 15
      } else {
        this.hintPosDis = 20
      }
    },
    moveHint (x1, y1, x2, y2) {
      let requestAnimationFrame = window.requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                                  window.webkitRequestAnimationFrame || window.msRequestAnimationFrame
      let len = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
      let skip = 1
      if (len < 50) {
        skip = 2
      } else if (len < 100) {
        skip = 4
      } else if (len < 200) {
        skip = 6
      } else if (len < 400) {
        skip = 8
      } else if (len < 800) {
        skip = 10
      } else if (len < 1600) {
        skip = 12
      } else {
        skip = 14
      }
      let cnt = 0
      if (!len) return

      // console.log('--------------')
      let step = () => {
        cnt += 1
        if (cnt > 0) {
          let dis = 1
          if (skip <= 6) {
            dis = this.cubicEaseInOut(cnt, 1, 1, skip) - 1
          } else {
            dis = this.quadEaseOut(cnt, 1, 1, skip) - 1
          }
          let dx = x1 + (len * dis) * (x2 - x1) / len
          let dy = y1 + (len * dis) * (y2 - y1) / len
          // console.log('skip', cnt, skip, dx.toFixed(2))
          if (skip < 4) {
            this.hintLeftAuto = dx
            this.hintTopAuto = dy
          } else {
            if ((x2 > this.hintLeftAuto && dx > this.hintLeftAuto) || (x2 < this.hintLeftAuto && dx < this.hintLeftAuto)) {
              this.hintLeftAuto = dx
            }
            if ((y2 > this.hintTopAuto && dy > this.hintTopAuto) || (y2 < this.hintTopAuto && dy < this.hintTopAuto)) {
              this.hintTopAuto = dy
            }
          }
        }
        if (cnt < skip) {
          requestAnimationFrame(step)
        }
      }
      requestAnimationFrame(step)
      // if (skip < 4) {
      //   requestAnimationFrame(step)
      // } else {
      //   this.queue.push(skip, step)
      // }
    },
    slplitDataValue (data) {
      let group = []
      data.forEach((item, idx2) => {
        if (Array.isArray(item.value)) {
          item.value.forEach((val, idx1) => {
            if (!group[idx1]) group[idx1] = []
            group[idx1][idx2] = {name: item.name, value: val}
          })
        } else {
          group[0] = group[0] || []
          group[0][idx2] = {name: item.name, value: item.value}
        }
      })
      if (group.length > 1) {
        this.isGroupOne = false
        if (this.legend.length < group.length) {
          group.forEach((item, index) => {
            this.legend[index] = index + 1
          })
        }
      } else {
        this.isGroupOne = true
      }
      return group
    },
    getDataMaxValue (val) {
      let maxVal = 0
      this.data.forEach(item => {
        if (Array.isArray(item.value)) {
          item.value.forEach(val => {
            if (val > maxVal) maxVal = val
          })
        } else {
          if (item.value > maxVal) maxVal = item.value
        }
      })
      return maxVal
    },
    getBarMaxValue (val) {
      let maxVal = 0
      let times = 5
      if (val >= 1 && val <= 10) {
        maxVal = this.getMultiCeil(val, 2)
      } else if (val > 10) {
        times = this.getTimes(val)
        maxVal = this.getMultiCeil(val, times)
      }
      return maxVal
    },
    getMultiCeil (val, times) {
      let maxVal = Math.ceil(val)
      // 取 times 的整数倍 (2, 5, 10, 50, 100)
      let multi = Math.ceil(maxVal / times)
      maxVal = times * multi
      return maxVal
    },
    getTimes (val) {
      let temp = val
      let cnt = 0
      let i = 0
      let re = 1
      // 12/10=1.2   50   5*1=5
      // 61/10=6.1   100  10*1=10
      //
      // 450/10=45    45/10=4.5    500   5*10=50
      // 809/10=80.9  80.9/10=8.9  1000  10*10=100
      while (temp > 10) {
        temp /= 10
        cnt += 1
      }
      if (temp <= 5) {
        i = 0
        re = 5
        while (i < cnt - 1) {
          re *= 10
          i += 1
        }
      } else if (temp <= 10) {
        i = 0
        re = 10
        while (i < cnt - 1) {
          re *= 10
          i += 1
        }
      }
      return re
    },
    lightenDarkenColor (col, amt) {
      // Lighten var NewColor = lightenDarkenColor('#F06D06', 20)
      // Darken var NewColor = lightenDarkenColor('#F06D06', -20)
      var usePound = false
      if (col[0] === '#') {
        col = col.slice(1)
        usePound = true
      }
      if (col.length === 8) {
        // 处理透明
        var rgb = col.slice(0, 6)
        var a = col.slice(6, 8)
        a = parseInt(a, 16)
        a -= amt * 2
        a = Number(a).toString(16)
        return '#' + rgb + a
      }
      if (col.indexOf('rgba') === 0) {
        // 处理透明
        var lastCommaIndex = col.lastIndexOf(',')
        var head = col.slice(0, lastCommaIndex + 1)
        var tail = col.slice(lastCommaIndex + 1, col.length)
        var opacity = tail.slice(0, tail.length - 1)
        var op = Number(opacity)
        var light = amt / 100
        if (op > light) {
          op -= light
        }
        return head + op + ')'
      }
      var num = parseInt(col, 16)
      var r = (num >> 16) + amt
      if (r > 255) {
        r = 255
      } else if (r < 0) {
        r = 0
      }
      var b = ((num >> 8) & 0x00FF) + amt
      if (b > 255) {
        b = 255
      } else if (b < 0) {
        b = 0
      }
      var g = (num & 0x0000FF) + amt
      if (g > 255) {
        g = 255
      } else if (g < 0) {
        g = 0
      }
      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    },
    quadEaseOut: function (t, b, c, d) {
      return -c * (t /= d) * (t - 2) + b
    },
    cubicEaseInOut (t, b, c, d) {
      if ((t /= d / 2) < 1) return c / 2 * t * t * t + b
      return c / 2 * ((t -= 2) * t * t + 2) + b
    }
  }
}
