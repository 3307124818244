import { authenticate, catchExpired } from '../utils/auth'

export default {
  data () {
    return {
      userSum: [],
      userAccess: [],
      apiAccess: [],
      optionsUserSum: {
        title: {
          text: '用户总数变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        line: {
          boundaryGap: '5%',  // 20%, 0 10 两边轴内总空格
          colors: ['#546570', '#c23531']
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'fixed'
        },
        x: {
          label: true,
          axis: true,
          tick: true
        },
        y: {
          label: true,
          axis: true,
          tick: true
        },
        grid: {
          line: true
        },
        legend: ['所有用户', '登录用户']  // ''/[], 数据value的label, array
      },
      optionsUserAccess: {
        title: {
          text: '用户访问数变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        bar: {
          interval: 'auto',  // 'auto', 'none', 'half', 'one-third', 'one-five', 0, 10
          background: 'auto',  // 'auto', 'selected'
          colors: ['#546570', '#c23531']
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'bottom'
        },
        x: {
          label: true,
          labelRotate: false,
          axis: true,
          tick: true
        },
        y: {
          label: true,
          // labelRotate: true,
          axis: true,
          tick: true
        },
        grid: {
          line: true
        },
        legend: ['所有用户', '登录用户']
      },
      optionsApiAccess: {
        title: {
          text: 'API访问数变化',
          top: 35,
          style: {
            'font-size': '18px',
            'font-weight': 600
          }
        },
        margin: {
          top: 60,
          right: 60,
          bottom: 60,
          left: 80
        },
        bar: {
          interval: 'auto',  // 'auto', 'none', 'half', 'one-third', 'one-five', 0, 10
          background: 'auto',  // 'auto', 'selected'
          colors: []
        },
        hint: {
          pos: 'auto'  // 'auto', 'top', 'bottom'
        },
        x: {
          label: true,
          labelRotate: false,
          axis: true,
          tick: true
        },
        y: {
          label: true,
          // labelRotate: true,
          axis: true,
          tick: true
        },
        grid: {
          line: true
        },
        legend: []
      }
    }
  },
  mounted () {
    this.loadUserSum()
    this.loadUserAccess()
    this.loadApiAccess()
  },
  methods: {
    loadUserSum () {
      this.$http.get(`${this.httpRoot}/statistic/user/sum`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          let re = this.resolveData(res.data || [])
          this.userSum = re.data
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadUserAccess () {
      this.$http.get(`${this.httpRoot}/statistic/user/access`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          let re = this.resolveData(res.data || [])
          this.userAccess = re.data
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadApiAccess () {
      this.$http.get(`${this.httpRoot}/statistic/api/access`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          let re = this.resolveData(res.data || [])
          this.apiAccess = re.data
          this.optionsApiAccess.legend = re.legend
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    resolveData (resource) {
      let datas = []
      let legend = {}
      resource.forEach(item => {
        let data = {name: '', value: []}
        Object.keys(item).forEach(prop => {
          if (prop === 'date') {
            data.name = item[prop]
          } else if (prop === 'user') {
            legend[prop] = ''
            data.value.splice(0, 0, item[prop])
          } else {
            legend[prop] = ''
            data.value.push(item[prop])
          }
        })
        datas.push(data)
      })
      legend = Object.keys(legend)
      return {
        data: datas,
        legend: legend
      }
    }
  }
}
